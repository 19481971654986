<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules" v-if="reMountedForm">
      <a-form-model-item label="设备编号" prop="deviceId" >
        <a-input v-model="form.deviceId" placeholder="请输入设备编号" />
      </a-form-model-item>
      <a-form-model-item label="车辆编号" prop="vehicleNo" >
        <a-input v-model="form.vehicleNo" placeholder="请输入车辆编号" />
      </a-form-model-item>
      <a-form-model-item label="绑定状态" prop="status" >
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{ d.dictLabel }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="租户ID" prop="tenantId" >
        <a-input v-model="form.tenantId" placeholder="请输入租户ID" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDeviceVehicle, addDeviceVehicle, updateDeviceVehicle } from '@/api/jt808/deviceVehicle'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      reMountedForm: true,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        deviceId: null,
        vehicleNo: null,
        status: 0,
        tenantId: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        deviceId: [
          { required: true, message: '设备编号不能为空', trigger: 'blur' }
        ],
        vehicleNo: [
          { required: true, message: '车辆编号不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '绑定状态不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
    open(val) {
      if (val) {
        this.reMountedForm = false
        this.$nextTick(() => {
          this.reMountedForm = true
        })
      }
    }
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        deviceId: null,
        vehicleNo: null,
        status: 0,
        tenantId: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDeviceVehicle(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDeviceVehicle(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addDeviceVehicle(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
