var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _vm.reMountedForm
        ? _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "设备编号", prop: "deviceId" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入设备编号" },
                    model: {
                      value: _vm.form.deviceId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deviceId", $$v)
                      },
                      expression: "form.deviceId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "车辆编号", prop: "vehicleNo" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入车辆编号" },
                    model: {
                      value: _vm.form.vehicleNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "vehicleNo", $$v)
                      },
                      expression: "form.vehicleNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "绑定状态", prop: "status" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (d, index) {
                      return _c(
                        "a-radio-button",
                        { key: index, attrs: { value: d.dictValue } },
                        [_vm._v(_vm._s(d.dictLabel))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "租户ID", prop: "tenantId" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入租户ID" },
                    model: {
                      value: _vm.form.tenantId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tenantId", $$v)
                      },
                      expression: "form.tenantId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bottom-control" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }